<section id="shopify-section-template" class="h-100 overflow-hidden m-0 p-0">
  <div #contentWrapper>
    <section class="b-hero">
        <div class="container">
            <article class="shadow b-hero__content py-5 rounded-5 bg-info c-text-line-wrapper">
                <div class="d-flex flex-wrap justify-content-between ">
                    <div class="text-content col-md-7 col-12">
                        <h1 class="fw-bold text-left typing-effect">
                            <span id="typing-text">Welcome To</span>
                            <br>
                            <span class="fw-bold">Mechtron Water Services</span>
                        </h1>
                        <p class="b-hero__subtitle text-left pt-3">
                            <b>"Mechtron Water Services"</b> is a Maharashtra Based Company who is engaged in the field of Waste Water Solutions. "Mechtron Water Services" is a group of experts in the field who are collaborating to create an Innovative & Reasonable wastewater treatment plant. We make use of innovative, eco-friendly technology. "Mechtron" has expanded its water treatment system from domestic to Industrial sectors. The mechanisms make sure that no water will be wasted and that the environment will not affect.
                        </p>
                        <p class="b-hero__subtitle text-left">
                            <b>"Mechtron Water Services"</b> is dedicated to developing long-term solutions for industrial wastewater that is released into bodies of water. This will aid in environmental protection by treating and rendering any hazardous chemical substances soluble. The wastewater treatment plant will process organic and inorganic pollutants, as well as all other impurities. We are attempting to meet our clients' objectives. "Mechtron" is well-known in the industry for its high-quality effluent wastewater treatment plants. We accomplished the treatment of water through cost-effective treatment technology. Creating and rendering soluble any dangerous chemical compounds will help to protect the environment.
                        </p>
                    </div>
                    <div class="col-md-5 col-12 text-center">
                        <img src="../../../assets/images/new-home.png" alt="New Home" class="img-fluid hanging-image">
                    </div>
                </div>
            </article>
        </div>
    </section>

  
      

    <div class="b-hero__slider mb-5  position-relative">
        <section id="image-carousel" class="splide" aria-label="Beautiful Images">
            <div class="splide__track">
                <ul class="splide__list">
                    <li class="splide__slide">
                        <img class="img-fluid" src="../../../assets/images/waste-water-treatment-plant.jpg" alt="Waste Water Treatment Plant">
                    </li>
                    <li class="splide__slide">
                        <img class="img-fluid" src="../../../assets/images/dws-landustries-landy-adu-dhabi-750px.jpg" alt="Landustries Abu Dhabi">
                    </li>
                    <li class="splide__slide">
                        <img class="img-fluid" src="../../../assets/images/grey-water-treatment-plants1.jpg" alt="Grey Water Treatment Plant">
                    </li>
                </ul>
            </div>
        </section>
    </div>

    <section class="b-hero">
        <div class="container">
          <div class="row g-4">
            <!-- Best Selling Services Text -->
            <div class="col-lg-3 col-md-6 col-12">
              <h4 class="fw-bold text-align-left">Best Selling Services</h4>
              <p class="text-secondary text-align-left">Easiest way to healthy life by buying your favorite plants</p>
              <button type="button" class="btn btn-success" routerLink="/services">
                See more <img class="fs-5" src="../../../assets/images/arrow-right2.svg" alt="arrow-image">
              </button>
            </div>
      
            <!-- Sewage Treatment Plant Card -->
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card text-bg-success">
                <img src="../../../assets/images/sewage-treatment.jpg.jpg" class="card-img-top" alt="Sewage Treatment Plant">
                <div class="card-body">
                  <p class="card-text fs-5">Sewage Treatment Plant</p>
                </div>
              </div>
            </div>
      
            <!-- Dissolved Air Floatation Card -->
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card text-bg-success">
                <img src="../../../assets/images/sewage-treatment.jpg.jpg" class="card-img-top" alt="Dissolved Air Floatation">
                <div class="card-body">
                  <p class="card-text fs-5">Dissolved Air Floatation</p>
                </div>
              </div>
            </div>
      
            <!-- CPI Separator Card -->
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card text-bg-success">
                <img src="../../../assets/images/sewage-treatment.jpg.jpg" class="card-img-top" alt="CPI Separator">
                <div class="card-body">
                  <p class="card-text fs-5">CPI Separator</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    <section  id="reasons">
        <div id="targetId" class="container">
            <h2 class="mt-3 text-center fw-bold mb-5">About Us</h2>
            <p class="text-justify text-secondary"><strong>"Mechtron Water Services"</strong>, a Mechtron Group of Company, is renowned as a leading company providing Waste Water Solutions. Our Services are described briefly as below.</p>
            <p class="text-justify text-secondary">We, at <strong>"Mechtron Water Services"</strong>, aim to design the latest technological solutions. We take pride in providing sustainable, innovative, and environment-friendly treatments to satisfy our clients' needs. We design, manufacture, supply, install, and commission Waste Water treatment Plants & Equipment. "Mechtron Water Services" is committed to delivering high-quality plants, improved technology-based solutions, and our unbeaten competitiveness ensures complete customer satisfaction. Our products range from Packaged MBBR, MBR, SBR, STP, and ETP, oil skimmer, DAF, Lamella Clarifier, Surface Aerator, Oil Separator, and online mixing system, etc.</p>
        </div>
    </section>

    <section class="b-hero mb-5" id="vision">
        <div class="container">
            <h2 class="mt-3 text-center fw-bold mb-5">Vision</h2>
            <p class="text-justify text-secondary">Our Vision is to keep improving the quality & performance of its products and services and to expand business activity to the global environmental market by providing sustainable & innovative solutions.</p>
            <div class="container m-lg-auto w-50 mt-3">
                <img src="../../../assets/images/vison.png" alt="Vision" class="img-fluid">
            </div>
        </div>
    </section>
  </div>
</section>

