<section class="services-section container py-5">
    <h2 class="text-center mb-5">Our Services</h2>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
      
      <!-- Sewage Treatment Plant -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/sewage-treatment.jpg.jpg" alt="Sewage Treatment Plant" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Sewage Treatment Plant</h5>
            <p class="service-description">We provide advanced solutions for sewage treatment, including MBBR, MBR, SBR, and conventional methods.</p>
          </div>
        </div>
      </div>
  
      <!-- Effluent Treatment Plant -->
      <div class="col">
        <div class="service-card">
          <img src="../../../../assets/images/effluent-treatment.jpg" alt="Effluent Treatment Plant" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Effluent Treatment Plant</h5>
            <p class="service-description">Efficient treatment solutions for industrial effluent to ensure environmental safety and compliance.</p>
          </div>
        </div>
      </div>
  
      <!-- Dissolved Air Floatation -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Dissolved Air Floatation" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Dissolved Air Floatation</h5>
            <p class="service-description">Our Dissolved Air Floatation systems are ideal for separating suspended solids, oils, and grease from wastewater.</p>
          </div>
        </div>
      </div>
  
      <!-- CPI Separator -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="CPI Separator" class="service-image">
          <div class="service-content">
            <h5 class="service-title">CPI Separator</h5>
            <p class="service-description">Cost-effective solutions for oil and water separation using Corrugated Plate Interceptor technology.</p>
          </div>
        </div>
      </div>
  
      <!-- Oil Skimmer -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Oil Skimmer" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Oil Skimmer</h5>
            <p class="service-description">Our oil skimmers efficiently remove floating oil from water, improving overall water quality.</p>
          </div>
        </div>
      </div>
  
      <!-- Oil & Grease Interceptor -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Oil & Grease Interceptor" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Oil & Grease Interceptor</h5>
            <p class="service-description">Specialized systems to trap and remove oil and grease from wastewater, preventing system blockages.</p>
          </div>
        </div>
      </div>
  
      <!-- Agitator for Chemical Mixing -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Agitator for Chemical Mixing" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Agitator for Chemical Mixing</h5>
            <p class="service-description">Our agitators are designed for efficient chemical mixing and process control in wastewater treatment.</p>
          </div>
        </div>
      </div>
  
      <!-- Lamella Clarifier -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Lamella Clarifier" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Lamella Clarifier</h5>
            <p class="service-description">Highly efficient, compact clarifiers for solid-liquid separation in water treatment.</p>
          </div>
        </div>
      </div>
  
      <!-- Clarifier -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Clarifier" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Clarifier</h5>
            <p class="service-description">Our clarifiers efficiently remove solids from water, improving water quality and reducing contamination.</p>
          </div>
        </div>
      </div>
  
      <!-- HRSCC -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="HRSCC" class="service-image">
          <div class="service-content">
            <h5 class="service-title">HRSCC</h5>
            <p class="service-description">High-Rate Solid Contact Clarifiers (HRSCC) designed for high-efficiency water clarification.</p>
          </div>
        </div>
      </div>
  
      <!-- Filters (PSF, ACF, DMF, etc.) -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Filters (PSF, ACF, DMF, etc.)" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Filters (PSF, ACF, DMF, etc.)</h5>
            <p class="service-description">We offer a variety of filtration systems such as Pressure Sand Filters, Activated Carbon Filters, and Dual Media Filters.</p>
          </div>
        </div>
      </div>
  
      <!-- Self Cleaning Filter -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Self Cleaning Filter" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Self Cleaning Filter</h5>
            <p class="service-description">Automatic self-cleaning filters that ensure continuous operation and high-quality filtration.</p>
          </div>
        </div>
      </div>
  
      <!-- Surface Aerator (Fixed & Floating) -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Surface Aerator (Fixed & Floating)" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Surface Aerator (Fixed & Floating)</h5>
            <p class="service-description">Energy-efficient aeration systems for wastewater treatment, available in fixed and floating designs.</p>
          </div>
        </div>
      </div>
  
      <!-- Dosing System -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Dosing System" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Dosing System</h5>
            <p class="service-description">Accurate dosing systems for chemicals in water and wastewater treatment processes.</p>
          </div>
        </div>
      </div>
  
      <!-- Spares & Consumables -->
      <div class="col">
        <div class="service-card">
          <img src="../../assets/images/effluent-treatment.jpg" alt="Spares & Consumables" class="service-image">
          <div class="service-content">
            <h5 class="service-title">Spares & Consumables</h5>
            <p class="service-description">We supply spares and consumables such as diffusers, membranes, and chemicals for water treatment plants.</p>
          </div>
        </div>
      </div>
  
    </div>
  </section>
  